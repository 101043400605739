import { I18nextContext } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { usePagePortaldataCs } from '../hooks/cs/page-portal.metadata';
import { usePagePortaldataEn } from '../hooks/en/page-portal.metadata';

export function PagePortalMetadata(): any {
  const { language } = React.useContext(I18nextContext);
  let data;
  if (language == 'cs') {
    data = usePagePortaldataCs();
  } else {
    data = usePagePortaldataEn();
  }

  console.log(data);
  return data;
}
